import type OktaJwtVerifier from "@okta/jwt-verifier";

export enum Roles {
  Admin = "Admin",
  CatOps = "CatOps",
  User = "User",
  Expert = "Expert",
  Marketer = "Marketer",
  SC = "SC",
}

export enum Action {
  ViewCatalog = "ViewCatalog",
  ViewTasks = "ViewTasks",
  ManageOpsStatus = "ManageOpsStatus",
  EditCatalog = "EditCatalog",
  DeleteCatalog = "DeleteCatalog",
  DeleteCompany = "DeleteCompany",
  DeactivateEntity = "DeactivateEntity",
  EditCompanyStructure = "EditCompanyStructure",
  EditCompanyMerchandising = "EditCompanyMerchandising",
  EditSKU = "EditSKU",
  EditSKUMapping = "EditSKUMapping",
  EditProductMapping = "EditProductMapping",
  SetDocumentStatus = "SetDocumentStatus",
  DownloadDocument = "DownloadDocument",
  AccessVibeAdmin = "AccessVibeAdmin",
  EditDocuments = "EditDocuments",
  Impersonate = "Impersonate",
}

const EDIT_COMPANY_ALLOWED_ROLES = new Set([
  Roles.Marketer,
  Roles.Admin,
  Roles.Expert,
  Roles.CatOps,
  Roles.SC,
]);

const ROLE_GROUP_MAP = new Map<Roles, string>([
  [Roles.Admin, "Backoffice: Admins"],
  [Roles.CatOps, "Backoffice: Catalog Ops"],
  [Roles.User, "Backoffice: Users"],
  [Roles.Marketer, "Backoffice: Marketer"],
  [Roles.Expert, "Backoffice: Expert"],
  [Roles.SC, "Backoffice: SaaS Consultants"],
]);

const ROLE_ACTIONS: Record<Roles, Set<Action>> = {
  [Roles.Admin]: new Set(Object.values(Action)),
  [Roles.CatOps]: new Set([
    Action.ViewCatalog,
    Action.ViewTasks,
    Action.ManageOpsStatus,
    Action.EditCatalog,
    Action.EditCompanyStructure,
    Action.EditSKU,
    Action.EditSKUMapping,
    Action.DeactivateEntity,
    Action.DeleteCatalog,
    Action.SetDocumentStatus,
    Action.EditDocuments,
  ]),
  [Roles.User]: new Set([Action.ViewCatalog]),
  [Roles.Expert]: new Set([
    Action.ViewCatalog,
    Action.EditCatalog,
    Action.EditCompanyStructure,
    Action.EditSKU,
    Action.EditSKUMapping,
    Action.DeactivateEntity,
  ]),
  [Roles.Marketer]: new Set([
    Action.ViewCatalog,
    Action.EditCompanyMerchandising,
  ]),
  [Roles.SC]: new Set([
    Action.ViewCatalog,
    Action.ViewTasks,
    Action.ManageOpsStatus,
    Action.EditCatalog,
    Action.EditCompanyStructure,
    Action.EditSKU,
    Action.EditSKUMapping,
    Action.DeactivateEntity,
    Action.DeleteCatalog,
    Action.SetDocumentStatus,
    Action.EditDocuments,
    Action.DownloadDocument,
  ]),
};

export interface Claims extends OktaJwtVerifier.JwtClaims {
  name: string;
  sub: string;
  email: string;
  groups: string[];
}

export interface Session extends Claims {
  roles: Roles[];
}

export function convertClaimsToSession(claims: Claims): Session {
  const roles: Roles[] = [];

  for (const [role, group] of ROLE_GROUP_MAP.entries()) {
    if (claims.groups.includes(group)) {
      roles.push(role);
    }
  }

  // Ensure User role is always added for Admin
  if (roles.includes(Roles.Admin) && !roles.includes(Roles.User)) {
    roles.push(Roles.User);
  }

  return {
    ...claims,
    roles,
  };
}

export function canEditCompanyData(session: Session | undefined): boolean {
  return (
    session?.roles.some((role) => EDIT_COMPANY_ALLOWED_ROLES.has(role)) ?? false
  );
}

export function can(session: Session | undefined, action: Action): boolean {
  if (!session) return false;

  if (session.roles.includes(Roles.Admin)) {
    return true; // Admin has access to all actions
  }

  return session.roles.some((role) => ROLE_ACTIONS[role].has(action));
}

export function rolesThatCan(
  session: Session | undefined,
  action: Action,
): Array<string> {
  const roleLabels = [];

  if (session) {
    for (const role of session.roles) {
      if (ROLE_ACTIONS[role].has(action)) {
        roleLabels.push(ROLE_GROUP_MAP.get(role)!);
      }
    }
  }

  return roleLabels;
}

export function isAdmin(session: Session | undefined): boolean {
  if (!session) return false;
  return session?.roles.includes(Roles.Admin) ?? false;
}
